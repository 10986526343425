<template>
  <v-main>

    <v-container
        id="payment-container"
        fluid
        tag="section"
    >

      <base-material-card
          icon="mdi-family-tree"
          class="px-5 py-3"
          ref="form"
      >

        <v-card-text v-if="errors.length">
          <v-alert color="error"
                   outlined
                   dark
                   :key="error.id"
                   show variant="danger"
                   v-for="error in errors">
            {{ error }}
          </v-alert>
        </v-card-text>

        <v-form ref="paymentForm"
                v-model="valid"
                @submit.prevent="validate"
                lazy-validation
        >
          <v-row>
            <v-col
                cols="12"
                lg="6"
            >
              <v-text-field
                  v-model="payment.amount"
                  label="Payment Amount (XXXX.XX)"
                  id="paymentAmount"
                  :rules="rules.paymentAmount"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col
                cols="12"
                lg="6"
            >
              <v-text-field
                  v-model="computedPaymentDate"
                  label="Payment Date"
                  type="date"
                  id="paymentDate"
                  :rules="rules.required"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col
                cols="12"
                lg="6"
            >
              <v-select
                  v-model="payment.method"
                  label="Payment Method"
                  id="paymentMethod"
                  :items="paymentMethods"
                  :rules="rules.required"
              />
            </v-col>
          </v-row>

          <v-row v-if="computedRequireBankBranch">
            <v-col
                cols="12"
                lg="6"
            >
              <v-text-field
                  v-model="payment.bankDepositBranch"
                  label="Wells Fargo Bank Branch"
                  id="bankBranch"
                  :rules="rules.required"
              />
            </v-col>

          </v-row>

          <v-row v-if="computedRequireBankOrigin">
            <v-col
                cols="12"
                lg="6"
            >
              <v-text-field
                  v-model="payment.bankTransferOrigin"
                  label="Transfer Origin Bank"
                  id="originBank"
                  :rules="rules.required"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col
                cols="12"
                lg="6"
            >
              <v-text-field
                  v-model="payment.reference"
                  label="Reference/Tracking Number"
                  id="paymentReference" />
            </v-col>
          </v-row>

          <v-row>
            <v-col
                cols="12"
                lg="6"
            >
              <v-select
                  v-model="computedStatus"
                  label="Status"
                  id="paymentStatus"
                  :items="paymentStatusMap"
                  :rules="rules.required"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col
                cols="12"
                lg="6"
            >
              <v-text-field
                  v-model="comment"
                  label="Comment"
                  id="paymentComment"
                  name="paymentComment"
              />
            </v-col>

          </v-row>


          <v-row>
            <v-col
                cols="12"
                lg="6"
            >
              <div class="my-2">
                <v-btn
                    color="primary"
                    dark
                    type="submit"
                    id="submitReviewPayment"
                >
                  Submit
                </v-btn>
              </div>
            </v-col>
          </v-row>

        </v-form>


      </base-material-card>

      <v-expansion-panels
          multiple
          light
          hover
          v-model="expandedPanels"
          v-if="payment.id"
      >
        <v-expansion-panel>
          <v-expansion-panel-header>Audit Trail</v-expansion-panel-header>

          <v-expansion-panel-content>
            <shared-audit-trail-view :item-id="paymentId"></shared-audit-trail-view>
          </v-expansion-panel-content>

        </v-expansion-panel>
      </v-expansion-panels>

    </v-container>

  </v-main>
</template>

<script>
import api from '@/services/api'
import validation from "@/utils/validation"
import constants from "@/utils/constants"
import SharedAuditTrailView from '../shared/AuditTrailView'
import moment from "moment";

export default {
  name: 'CorePaymentView',

  components: {
    SharedAuditTrailView
  },

  async created() {
    this.paymentId = this.$route.params.paymentId
    this.invoiceId = this.$route.params.invoiceId

    if (this.paymentId) {
      this.fetchPayment()
    }
  },

  computed: {
    computedPaymentDate: {
      get() {
        return moment(this.payment.date).format('YYYY-MM-DD')
      },
      set(value) {
        this.payment.date = value
      }
    },
    computedStatus: {
      get() {
        return this.paymentStatusMap[this.payment.status]
      },
      set(value) {
        this.payment.status = this.paymentStatusMap.indexOf(value)
      }
    },
    computedRequireBankOrigin() {
      return this.payment.method === constants.PAYMENT_METHOD_BANK_TRANSFER_NON_WF
    },

    computedRequireBankBranch() {
      return this.payment.method === constants.PAYMENT_METHOD_BANK_DEPOSIT
    }
  },


  methods: {
    validate() {
      if(this.$refs.paymentForm.validate()) {
        return this.updatePayment()
      }
    },
    fetchPayment() {
      api
          .fetchPayment(this.invoiceId, this.paymentId)
          .then(response => {
            this.payment = response.data
          })
          .catch(err => this.handleError(err))
    },
    updatePayment() {
      let payload = this.payment
      payload.comment = this.comment
      api
          .updatePayment(this.invoiceId, this.paymentId, payload)
          .then(response => {
            if (response.status === 200) {
              this.$router.push(`/invoices/${this.invoiceId}/view`)
            }
          })
          .catch(err => this.handleError(err))
    },
    handleError(err) {
      let errorMessage = api.parseResponseError(err)
      this.errors.push(errorMessage)
    }
  },

  data() {
    return {
      valid: true,
      errors: [],
      invoice: {},
      payment: {},
      paymentId: null,
      expandedPanels: [0],
      auditLogs: [],
      comment: null,
      paymentMethods: constants.getPaymentMethods(),
      paymentStatusMap: constants.getPaymentStatusMap(),
      rules: {
        required: [
          v => !!v || 'required'
        ],
        date: [
          v => validation.isDate(v) || 'must be a date'
        ],
        paymentAmount: [
          v => validation.isCurrency(v) || 'must be in currency format'
        ]
      }
    }
  }
}
</script>

<style lang="sass">
.v-expansion-panel-header
  font-size: 1.15em
th
  vertical-align: top

th.audit-date
  width: 15%
th.audit-user
  width: 10%

</style>
